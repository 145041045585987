import css from './sass/style.scss';

require('./js/polyfills.js');
require('./js/accordion.js');
require('./js/carousels.js');
require('./js/countup.js');
require('./js/glightbox.js');
require('./js/helpers.js');
require('./js/init.js');
require('./js/inview.js');
require('./js/ismobile.js');
require('./js/nav.js');
require('./js/scrollpos.js');
require('./js/smoothscroll.js');
//require('./js/smoothscroll.js');

require('./img/about/marika.webp');
require('./img/about/marika@2x.webp');

require('./img/about/career/01.webp');
require('./img/about/career/01@2x.webp');
require('./img/about/career/02.webp');
require('./img/about/career/02@2x.webp');
require('./img/about/career/03.webp');
require('./img/about/career/03@2x.webp');
require('./img/about/career/04.webp');
require('./img/about/career/04@2x.webp');
require('./img/about/career/05.webp');
require('./img/about/career/05@2x.webp');
require('./img/about/career/06.webp');
require('./img/about/career/06@2x.webp');
require('./img/about/career/07.webp');
require('./img/about/career/07@2x.webp');
require('./img/about/career/08.webp');
require('./img/about/career/08@2x.webp');
require('./img/about/career/09.webp');
require('./img/about/career/09@2x.webp');
require('./img/about/carousel/logo-tvb.webp');
require('./img/about/carousel/logo-tvb@2x.webp');

require('./img/clients/algida-logo.webp');
require('./img/clients/algida-logo@2x.webp');
require('./img/clients/domestos-logo.webp');
require('./img/clients/domestos-logo@2x.webp');
require('./img/clients/dove-logo.webp');
require('./img/clients/dove-logo@2x.webp');
require('./img/clients/knor-logo.webp');
require('./img/clients/knor-logo@2x.webp');

require('./img/feel/klara.webp');
require('./img/feel/klara@2x.webp');

require('./img/gatherteam/fll-logo.webp');
require('./img/gatherteam/fll-logo@2x.webp');

require('./img/grid/factory.webp');
require('./img/grid/factory@2x.webp');
require('./img/grid/offers.webp');
require('./img/grid/offers@2x.webp');
require('./img/grid/pro.webp');
require('./img/grid/pro@2x.webp');
require('./img/grid/student.webp');
require('./img/grid/student@2x.webp');

require('./img/herohome/img.webp');
require('./img/herohome/img@2x.webp');

require('./img/pro/agata.webp');
require('./img/pro/agata@2x.webp');
require('./img/pro/justyna.webp');
require('./img/pro/justyna@2x.webp');

require('./img/students/klara.webp');
require('./img/students/klara@2x.webp');

require('./img/technology/icons/chief.webp');
require('./img/technology/icons/chief@2x.webp');
require('./img/technology/icons/global.webp');
require('./img/technology/icons/global@2x.webp');
require('./img/technology/icons/innovations.webp');
require('./img/technology/icons/innovations@2x.webp');
require('./img/technology/icons/services.webp');
require('./img/technology/icons/services@2x.webp');

require('./img/ufll/agnieszka.png');
require('./img/ufll/agnieszka@2x.png');
require('./img/ufll/piotr.webp');
require('./img/ufll/piotr@2x.webp');
require('./img/ufll/award-01.webp');
require('./img/ufll/award-01@2x.webp');
require('./img/ufll/award-02.webp');
require('./img/ufll/award-02@2x.webp');
require('./img/ufll/award-03.webp');
require('./img/ufll/award-03@2x.webp');
require('./img/ufll/logo-ufll.webp');
require('./img/ufll/logo-ufll@2x.webp');

require('./img/uflp/dominik.webp');
require('./img/uflp/dominik@2x.webp');
//require('./img/uflp/malgorzata.webp');
//require('./img/uflp/malgorzata@2x.webp');

require('./img/uip/dominik.webp');
require('./img/uip/dominik@2x.webp');
require('./img/uip/marika.webp');
require('./img/uip/marika@2x.webp');

require('./img/wellbeing/klara.webp');
require('./img/wellbeing/klara@2x.webp');

